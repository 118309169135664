@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');



/* ----------- BASE STYLE -------- */

:root {
  --grey: #adb5bd;
  --blue: #1fa2ff;
  --dark: #495057;

}

html {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Manrope', sans-serif;
  color: var(--grey);
  font-size: 18px;
  width: 100%;
}

section {
  padding: 4rem 0;
}

svg {
  color: var(--grey);
}

.shadow-btn {
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 15%) !important;
  background: #fff !important;
  border: none !important;
  padding: 15px 32px;
  font-weight: 600;
}

.main-heading {
  font-size: 40px !important;
  font-weight: 600;
}


.main-subheading {
  background-color: rgba(133, 204, 255, 0.15) !important;
  width: max-content;
  margin: 0 auto;
  padding: 6px 12px;
  color: var(--blue);
  font-size: 15px;
  font-weight: bold !important;
  margin-bottom: 20px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 3.3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  color: var(--dark);
}

.sticky-scroll {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.main-content {
  position: relative;
  z-index: 7;
  padding-top: 0;
  background-color: #fff;
}

.shadow-lg {
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%) !important;
}

@keyframes updown {
  0% {
    transform: translateY(2%);
    animation-delay: 5s;

  }

  50% {
    transform: translateY(4%);
    animation-delay: 5s;

  }

  100% {
    transform: translateY(2%);
    animation-delay: 5s;
  }
}

.module-page .main-content {
  background-color: #f8f9fa !important;
}

/* -----------CUSTOM SCROLL -------- */

::-webkit-scrollbar {
  width: 10px;
  border-radius: 2em;
  background-color: var(--lightPurple);
}

::-webkit-scrollbar-thumb {
  border-radius: 2em;
  background-color: #282f4e;
}


@media only screen and (max-width:767px) and (min-width:320px) {

  .main-heading {
    font-size: 28px !important;
    line-height: 1.5;
  }

  section {
    padding: 2rem 0;
  }
  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 3.5rem !important;
}

.toggle-menu svg {
  font-size: 29px;
}
nav.navbar.navbar-expand-lg {
  padding-inline: 0 !important;
}
body {
	overflow-x:hidden;
}

}