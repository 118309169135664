video {
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    width:100%;
}
#fifth{
   background: url('../../../../assets/images/color-particles-2.svg')no-repeat center top;
}
.image-text-wrapper-first img, .image-text-wrapper-second img {
    width: 100% !important;
}
.widthnew{
    width: 75%;
}
.image-text-wrapper-first, .image-text-wrapper-second {
    text-align: center;
}
.image-wrap {
    text-align: center;
}

.image-text-content {
    text-align: left;
}

.image-text-wrapper-second {
    text-align: right;
}

.check_all {
    margin-top: 2rem;
}

.icon-play {
    width: 90px;
    height: 90px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%) !important;
}

.icon-play svg {
    color: var(--blue) !important;
    font-size: 30px;
}



@media only screen and (max-width:992px) and (min-width:320px){

    .widthnew{
        width: 100% !important;
    }
    .image-text-wrapper-first img, .image-text-wrapper-second img {
        width: 100% !important;
    }
    .image-text-content {
        margin-top: 2rem;
      }
      .image-text-wrapper-second {
        flex-direction: column-reverse;
    }
}
