
#featured {
    /* background-color: #72e6fa; */
    background-color: #0000000a;
    position: relative;
    z-index: 88;
}
.feature-img {
    width: 100%;
    margin: auto;
}
.feature-img img{
    width: 100%;
}
.feature-items {
    display: flex;
    align-items: center;
    column-gap: 15px;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important; 
    padding:30px;
    border-radius: 5px;
    background-color: #fff !important;
    margin-bottom: 20px;

}
.feature-content .row {
    justify-content: space-evenly;
}

.feature-items .icon-cirle {
    width: 60px;
    height: 60px;
    background-color: rgba(133, 204, 255, 0.15) !important;
}

.feature-items .icon-cirle svg {
    color: #009eff !important;
    font-size: 30px;
}

h2.feature-title-h2 {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 15px;
}

.featurePage-items .feature-img svg {
    font-size: 45px;
    text-align: right;
    margin-bottom: 20px;
}


@media only screen and (max-width:992px) and (min-width:320px){


    .featured-content-row {
        gap: 2rem;
    }
    h2.feature-title-h2 {
        margin-bottom: 9px;
    }

}


