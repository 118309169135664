
iframe {
    border: 0;
    width: 100%;
    /* height: 950px; */
}

.ContactPage-info .features-icon {
    margin: 0 !important;
}

.ContactPage-info  .features-icon svg {
    color: #0072ff !important;
    font-size: 25px;
    width: 25px;
    height: 25px;
}
.ContactPage-info  .features-icon {
    background: #66aaff26;
    padding: 14px;
}

.ContactPage-info .features-content .features-desc {
    font-size: 16px;
    margin: 10px 0 !important;
}

.contact-page .features-content .features-desc {
    margin: 10px 0 !important;
}

.form-bg-image img {
    width: 65%;
    margin-left: 35%;
}
.form-bg-image {
    position: relative;
}

.contact-form {
    position: absolute;
    top: 15%;
    padding: 0%;
    width: 45%;
    background: #fff;
}
h2.contact-form-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

p.form-subheading {
    font-size: 15px;
    margin-bottom: 20px;
}


@media only screen and (max-width:992px) and (min-width:320px){

    .form-bg-image img {
        width: 100%;
        margin-left: 0;
    }
    .contact-form {
        position: static;
        padding: 7%;
        width: 100%;
        background: #fff;
        margin-top: 2rem;
    }
    .bubble-images {
        display: none;
    }

}