

.form {
    width: 100%;
    background-color: var(--Dark3);
    border-radius: 5px;
}
.form_input input, .form_input textarea {
    width: 100%;
    padding: 12px 15px;
    color: #000;
    margin-bottom: 20px;
    background-color: #f8f9fa !important;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: .8rem;
}
button.submit_btn.btn {
    width: 100%;
    background: #005bcc !important;
    color: #fff;
    padding: 12px 15px;
    font-weight: 600;
}
 .form_input textarea {
    color: #000;
}

@media only screen and (max-width: 992px){

    .form {
        margin-top: 3rem;
    }

}