

.card-top-sub.main-subheading {
    margin: 0;
    /* background-color: #f8f9fa !important;
    color: #adb5bd !important; */
}

.pricng-card-top {
    padding: 20px;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    border-radius: 0.3rem;
}
.pricing-cards {
    margin: 3rem auto 0;
    max-width: 1000px;

}
.pricing-card-price {
    margin: 15px 0 10px;
}
.pricing-card-desc {
    color: #adb5bd !important;
    font-weight: 700;
    font-size: 14px;
}
.pricing-card-desc2 {
    color: #adb5bd !important;
    font-weight: 700;
    font-size: 11px;
}
.pricing-card-highlighted .pricing-card-desc2 {
    color: white !important;
}
ul.pricing-list {
    margin: 10px 0;
    padding: 40px 20px;
    list-style: none;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    border-radius: 4px;
    HEIGHT: 535PX;
}
.pricing-cards button.try-free.btn h6{
    color: var(--blue) !important;
}
.pricing-card-highlighted button.try-free.btn h6{
    color:white !important;
}
li.list-items svg {
    margin-right: 12px;
    font-size: 18px;
}
p.per-monthprice {
    font-size: 13px;
    margin-top: 6px;
}
.pricing-card-highlighted .per-monthprice {
    color: #ffffffd6;
}

.pricing-cards li.list-items {
    margin-bottom: 18px;
    font-size: 15px;
    font-weight: 600;
}
.pricing-cards li.list-items:last-child {
    margin-bottom: 0px;
}
.card-footer-heading {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.pricing-cards button.try-free.btn {
    color: var(--blue) !important;
    background-color: rgba(133, 204, 255, 0.15);
    width: 100% !important;
    font-size: 21px;
    font-weight: 700;
    padding: 10px 0;
}

.pricing-card-footer {
    padding: 20px;
    display: flex;
    column-gap: 30px;
    align-items: center;
    margin-top: 3rem;
}

.card-footer-img svg {
    font-size: 35px;
}
.card-footer-desc {
    font-size: 15px;
}

.card-footer-heading {
    margin-bottom: 8px;
}

.pricing-card-highlighted .pricng-card-top {
    background: var(--blue) !important;
}

.pricing-card-highlighted .card-top-sub.main-subheading {
    background-color: #f8f9fa !important;
    color: #fff !important;
    background-color: rgba(2, 3, 3, 0.1) !important;
}

.pricing-card-highlighted .pricing-card-price .main-heading {
    color: #fff;
}

.pricing-card-highlighted  .pricing-card-desc {
    color: #fff !important;
}

.pricing-cards .pricing-card-highlighted  button.try-free.btn {
    color: #ffffff !important;
    background-color: rgb(31 162 255);
    /* color: var(--blue) !important;
    background-color: rgba(133, 204, 255, 0.15); */
}


.pricing-card-highlighted li.list-items svg {
    color: var(--blue) !important;
}


.image-yellow img {
    width: 600px;
    height: auto;

    animation: updown 2s ease infinite;
}
.image-yellow.hero-moveable-images {
    top: 20%;
    right: -30%;
    position: absolute;
}

.image-pink.hero-moveable-images {
    top: 10%;
    left: -30%;
    position: absolute;
}

.image-pink img {
    width: 600px;
    height: auto;

    animation: updown 2s ease infinite;
}


@media (min-width:1440px) {
    .image-pink.hero-moveable-images {
        top: 10%;
        left: -20%;
        position: absolute;
    }
    .image-yellow.hero-moveable-images {
        top: 20%;
        right: -20%;
        position: absolute;
    }
 
    .pricing-cards .row {
        gap: 0;
    }
}

@media only screen and (max-width:992px) and (min-width:320px){

    .pricing-cards .row {
        gap: 2rem;
    }
    section#pricing .bubble-images {
        display: none;
    }

}