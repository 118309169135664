
 
    .store-logos1 img{
      width: 70%;
      margin-left: -13px;
    }
    .store-logos img{
      width: 60%;
    }

#footer {
    position: relative;
    padding-bottom: 0 !important;
    z-index: 3;
}
 #footer .image-three img {
    width: 50%;
    height: auto;
    bottom: 200px;
    right: -15%;
    position: absolute;
 }
 #footer .main-heading {
   font-size: 30px !important;
 }
 #footer .image-four img {
    width: 15%;
    height: auto;
    bottom: -30px;
    right: 20%;
    position: absolute;
 }
 #footer .image-two img {
    width: 15%;
    height: auto;
    top: -200px;
    left: 18%;
    position: absolute;
 }
  #footer .image-one img {
    width: 25%;
    height: auto;
    top: 0;
    left: -10%;
    position: absolute;
 }

 .footer-menus ul {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 30px;
    justify-content: center;
}