

.module-data-wrapper .check_all {
    margin-top: 2rem;
    padding: 12px 20px;
    display: block;
    width: max-content;
    font-weight: 500;
    color: #fff;
    background-color: #1fa2ff;
}
.module-image-wrapper img {
    width: 100%;
}
section#module-section {
    padding: 0 !important;
}
.module-data-wrapper , .module-image-wrapper{
    padding-top: 15%;
    padding-bottom: 5%;
}

.module-image-two {
    margin: 3rem 0;
}
@media only screen and (max-width:992px) and (min-width:320px){

    .second-row-module {
        flex-direction: column-reverse;
    }


}