.feature-bg {
    padding: 2% 0;
}

.feature-banner-imgs {
    display: flex;
    align-items: center;
    text-align: center;
}
.feature-banner-imgs img {
    width: 70%;
}
.feature-banner-col {
    display: flex;
    align-items: center;
}
.feature-banner-col h2 {
    color: #fff;
    font-weight: 600;
    font-size: 48px;
}
.feature-banner-col .banner-desc {
    margin: 1.5rem 0;
    color: rgba(255, 255, 255, 0.5) !important;
}
h2.featurePage-title-h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    color:#495057 !important;
    font-weight: 700;
}

ul.feature-sidebar-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
section#features-list {
    background: #f8f9fa !important;
}
.feature-sidebar-list {
    background-color: #fff !important;
    padding: 20px 40px;
}
span.sidebarfeature-img {
    margin-right: 12px;
}
.feature-sidebar-list li {
    color: #495057 !important;
    font-weight: 600;
}
span.sidebarfeature-img svg {
    font-size: 20px;
    color: #24469a;
}

.left-img img {
    position: relative;
    right: 161px;
    top: 20px;
}
.right-img img {
    position: relative;
    top: -25px;
}
.featurePage-items li {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.sidebarfeature-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #1fa2ff26;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebarfeature-img svg {
    color: #0072ff;
}

@media only screen and (max-width:992px) and (min-width:320px){
    .left-img img {
        right: 50px;
    }
    .right-img img {
        position: relative;
        top: -25px;
        left: 50px;
    }
    .feature-bg {
        padding: 3rem 0;
    }
    .feature-bg-row {
        gap: 3rem;
    }
    .featurePage-items {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}