

.screenshot-wrapper {
    text-align: center;
}

section#sixth {
    padding-bottom: 0;
}
.screenshot-wrapper .secreenshot-desc {
    margin-top: 15px;
}

.screenshot-image-item {
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    position: relative;
    padding: 15px;
}

.screenshot-image-content {
    position: absolute;
    bottom: 8%;
    text-align: center;
    left: 0;
    right: 0;
    padding: 0 30px;
}

.screenshot-title {
    font-weight: bold;
    color: #000;
    font-size: 17px;
    margin-bottom: 10px;
}
p.screenshot-desc {
    font-size: 15px;
}

.secreenshot-icon {
    width: 45px;
    height: 45px;
    background: var(--blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    box-shadow: 3px 2px #1fa2ff45;
}


.secreenshot-icon svg {
    color: #fff !important;
    font-size: 16px;
    font-weight: bold;
}
.list-two-col li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
ul.list-unstyled.d-flex.flex-wrap.list-two-col.mt-4.mb-4 li{
list-style-type: initial;
    color: #3384c6;
    white-space: pre;   
}
#seventhhh{
    padding: 4rem 0;
    margin: 4rem 0;
    background-color: #0000000a;
}
.testimonial-hero{
    background-color: #1fa2ff;
    padding: 20px;
    border-radius: 4px;
    margin: 20px 10px;
    height: 520px;
}
.testimonial-hero .feature-title-h2,.testimonial-hero .feature-desc,blockquote h6,blockquote p{
    color:white !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.slick-prev:before, .slick-next:before {
    color: #1fa2ff;
}
.testimonial-img img{
    margin-bottom:10px;
    height:180px;
}

@media only screen and (max-width:768px){

.testimonial-hero{
    background-color: #1fa2ff;
    padding: 20px;
    border-radius: 4px;
    margin: 20px 10px;
    height: 575px;
}
}
@media only screen and (max-width:992px) and (min-width:320px){

    .screenshot-image-wrapper {
        flex-wrap: wrap;
    }

    .screenshot-image-item {
        text-align: center;
    }
    .screenshot-image-item img {
        width: 75% !important;
    }    
    .screenshot-image-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
    }

}