

.features-content-wrap .live-demo {
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 15%) !important;
    background: #fff !important;
    border: none !important;
    padding: 15px 32px;
}

.features-content-wrap  .live_demo {
    text-align: center;
    margin-top: 3rem;
}
.features-img {
    width: 85% !important;
    margin: 0 auto;
}

.feature_button-wrap {
    margin: 2rem 0;
    display: flex;
    align-items: center;
}

.feature-button {
    background: #1fa2ff;
    color: #fff;
    padding: 12px 30px;
    font-size: 20px;
    font-weight: 600;
    border: none;
    border-radius: 0 !important;

} 
button.knowledge_base {
    background: #fff;
    color: var(--grey);
    padding: 8px 30px;
    border: none;
    font-size: 20px;
    border-radius: 0 !important;
}

.btn.feature-button svg {
    color: #fff !important;
    margin-right: 10px;
}
.feature-btn button.btn.btn-secondary {
    width: 100%;
    font-weight: 600;
    border-radius: 0;
    padding: 0.75rem 2rem;
    font-size: 1.125rem;
}

.features-content{
    padding: 20px 30px;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 1.5rem;
}

.features-content .service-title-h2 {
    font-size: 18px;
    font-weight: 600;
}

.features-content .features-desc {
    font-size: 16px;
    margin: 20px 0;
}

.features-icon {
    padding: 17px;
    background: #f6e28942;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.features-icon svg {
    color: #f1bb0b !important;
    font-size: 38px;
    width: 35px;
    height: 35px;
}



@media only screen and (max-width:992px) and (min-width:320px){

    .feature_button-wrap {
   
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    .features-icon {
        padding: 10px;
    }
    .features-icon svg {
        width: 30px;
        height: 30px;
    }
    .features-content .features-desc {
        font-size: 13px;
        margin: 10px 0;
    }


}