.hero-buttons .first.btn {
    background-color: #1fa2ff;
    color: #fff;
    margin-right: 20px;
    font-weight: bold;
}

section#pricing {
    position: relative;
}

.hero-banner {
    background-color: #f8f9fa !important;
}

.hero-buttons .second.btn {
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 15%) !important;

}

.hero-buttons .btn {
    font-size: 18px;
    padding: 12px 32px;

}



.hero-desc {
    margin: 2rem 0;
}

.hero-desc p {
    font-size: 20px;
}

.hero-banner-row {
    z-index: 2;
    position: relative;
}

section.hero-banner {
    position: relative;
    padding: 3% 0 8%;
}

section.hero-banner .image-one img {
    position: absolute;
    width: 25%;
    height: auto;
    top: -100px;
    left: -15%;
    animation: updown 2s ease infinite;
}

.banner-slider-img {
    padding-left: 50px !important;
}


.shadow-box {
    box-shadow: 0 0.5rem 1.2rem rgb(0 0 0 / 10%), 0 2rem 3rem rgb(0 0 0 / 15%) !important;
}


.image-two img {
    position: absolute;
    width: 10%;
    height: auto;
    top: -25%;
    left: 40%;
}

.image-three img {
    position: absolute;
    width: 50%;
    height: auto;
    bottom: 11%;
    right: -23%;
    animation: updown 2s ease infinite;
}

.image-four img {
    position: absolute;
    width: 13%;
    height: auto;
    bottom: 10%;
    right: 43%;
}
.slick-dots li {
    margin: 0 0px !important;
}
.slick-dots {
    bottom: -35px !important;
}

.hero-moveable-images {
    animation: updown 2s ease infinite;
}

.hero-top-content {
    justify-content: space-between;
}
.hero-top-content img {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width:992px) and (min-width:320px){

    .hero-banner-row {
        gap: 3rem;
    }
    .banner-slider-img {
        padding-left: 0 !important;
    }
    .row.banner-slider-img img {
        width: 100% !important;
    }
    p.clients-goal-desc {
        font-size: 16px;
    }
    .image-three img {
        position: absolute;
        width: 50%;
        height: auto;
        bottom: 35%;
        right: 0;
        animation: updown 2s ease infinite;
    }
    .hero-buttons .btn {
        font-size: 15px;
        padding: 12px 32px;
    }
}