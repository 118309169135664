.react-reveal.services-img {
    width: 25%;
    margin: auto;
}

section#second-section .main-heading {
    white-space: pre;
}

.client-goals-heading {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}
p.clients-goal-desc {
    margin-top: 12px;
}

section#second-section {
    padding-top: 0;
}

.service-content {
    padding: 15px;
    margin-bottom:20px;
    text-align: center;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
}
.icon-cirle svg {
    color: #009eff !important;
    font-size: 42px;

}
.services-row {
    margin: 20px 0;
}
.icon-cirle {
    width: 100px;
    height: 100px;
    background: #77cbff30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.service-title .service-title-h2 {
    font-size: 23px;
    margin: 20px 0 10px;
    font-weight: 600;
}
.learn_more-services a {
    color: #495057 !important;
    font-weight: 600;
    margin: 20px 0 !important;
}

.service-content a {
    text-decoration: none;
}

.second-icon-circle svg {
    color: #38e197 !important;
    font-size: 42px;
}
.second-icon-circle {
    background: #abffc161;
}

.third-icon-circle {
    background: #f6e28942;
}

.third-icon-circle svg {
    color: #f1bb0b !important;
    font-size: 42px;
}
.service-desc {
    font-size: 14px;
    margin-bottom: 20px !important;
    font-weight: 400 !important;
}
p.clients-goal-desc {
    margin-top: 20px;
    margin-bottom: 30px;
}


@media only screen and (max-width:992px) and (min-width:320px){


    .service-content {
        padding: 25px;
    }
    .service-title .service-title-h2 {
        font-size: 19px;
    }
    .icon-cirle {
        width: 60px !important;
        height: 60px !important;
    }
    .icon-cirle svg {
        font-size: 32px;
    }
    .services-row {
        gap: 25px;
    }
}
