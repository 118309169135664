.top-Header p {
    font-weight: 700 !important;
    font-size: 14px;
}

.header .relative-header-container {
    position: relative;
    z-index: 99999;
}
.header_shrink {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    right: 0;
    left: 0;
}
.header_shrink nav.navbar.navbar-expand-lg {
    background-color: #fff !important;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%) !important;
}
.home-page .header_shrink nav.navbar.navbar-expand-lg {
    background-color: #fff !important;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%) !important;
}
.home-page .header_shrink {
    background-color: transparent !important;
    position: fixed;
    top: 22px !important;
    width: 100%;
    margin: 0 auto;
    right: 0;
    left: 0;
    
  }

nav.navbar.navbar-expand-lg {
    background: rgba(255, 255, 255, 0.8) !important;
}

body.home-page nav.navbar.navbar-expand-lg {
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%) !important;
}
body.home-page .main-header {
    background-color: #f8f9fa !important;
}
.top-Header {
    padding-inline: 30px;
    align-items: center;
}
li.nav-item button {
    margin-left: 20px;
    background-color: var(--blue);
    color: #fff;
    font-weight: bold;
}
.navbar-brand img {
    width: 130px !important;
}
ul.navbar-nav {
    column-gap: 35px;
}

.main-header {
    z-index: 100; 
}

a.nav-link.active {
    color: #333333d9 !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 600;
    color: var(--grey);
}
.mobile-navigation {
    display: none;
}

.main-navigation {
    padding-inline: 15px !important;
}
.toggle-close {
    display:none;
  }

  .dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
    }
    
    .dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
    }
    .dropdown-hover:hover>.dropdown-menu {
        display: inline-block;
        position: absolute;
        right: 0;
        left: 0;
        border: 0;
    }
@media only screen and (max-width:992px) and (min-width:320px){
    .mobile-navigation {
        display: block;
    }
    a.nav-link.active {
        color: #fff !important;
    }
    .navigation-list {
        position: fixed;
        right: 0;
        height: 100%;
        left: 0;
        text-align: center;
        background: #000000f2;
        top: 0;
        padding: 2rem;
    }
    .navigation-list {
        padding-top: 5rem;
    }
    .social-header p {
        display: none;
    }
    .dwonload-btn-list {
        display: none;
    }
    .navigation-list {
        display: none;
    }
    .menu_active {
        display: block;
    }
    .top-Header {
        padding-inline: 0;
    }
    .sticky-heaher .container {
        padding: 0;
    }
    
    .toggle-close {
        position: absolute;
        top: 2%;
        right: 4%;
    }
    .toggle-close {
      display:block;
    }
}
