
.pricing-employee-cards .pricingPage-col.shadow-lg {
    padding: 30px 20px;
}
.pricing-employee-cards .main-heading {
    font-size: 32px !important;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}
.pricing-employee-cards span.pricing-list-employee-heading {
    display: block;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 15px;
}
.pricing-card-list1 {
    margin: 10px 0;
    padding: 20px;
    list-style: none;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    border-radius: 4px;
}
.pricing-card-list2 {
    margin: 10px 0;
    padding: 20px;
    list-style: none;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    border-radius: 4px;
    background: #1fa2ff;
}

.pricing-employee-cards .pricing-card-highlighted h2.main-heading, .pricing-employee-cards .pricing-card-highlighted .pricing-list-employee-heading, .pricing-employee-cards .pricing-card-highlighted li.list-items {
    color: #fff;
}
.pricing-employee-cards li.list-items {
    font-size: 12px;
		font-weight:600;
		margin-bottom:8px;
}

.pricing-employee-cards .pricing-card-highlighted .main-subheading {
    background-color: rgba(2, 3, 3, 0.1) !important;
    color: #fff !important;
}

.pricing-employee-cards .main-subheading {
    font-size: 24px;
    font-weight: 500 !important;
}



/* .pricingPage-cards .pricng-card-top {
    padding: 20px;
    box-shadow: none !important;
    border-radius: none;
} */
.pricingPage-cards ul.pricing-list svg {
    color: #0072ff !important;
}
ul.pricing-list-employee {
    list-style: none;
    padding: 0;
}

.pricingPage-cards .row {
    justify-content: center;
    column-gap: 0px;
}
.pricingPage-cards {
    max-width: 100%;
}
.pricingPage-cards .pricingPage-col {
    padding: 30px 15px;
}

/* section#pricing-cards .pricingPage-cards .pricing-card-highlighted button.try-free.btn {
    color: #fff !important;
    background-color: #0050b2;
    border: none;
    font-size: 18px;
    font-weight: 400;
}
section#pricing-cards .pricingPage-cards button.try-free.btn {
    background-color: #0072ff;
    color: #ffffff !important;
    width: 100% !important;
    font-size: 18px;
    font-weight: 400;
} */

.faq-question {
    color: #495057 !important;
    font-weight: 800;
    display: block;
    font-size: 25px;
    letter-spacing: 0;
}
p.faq-answer {
    font-size: 16px;
}
.pricingPage-cards {
    margin: 3rem auto 0;
    max-width: 100% !important;
}

.faq-footer button.btn.btn-grey {
    background: #eceff2;
    border-color: #eceff2;
    border-radius: 0;
    font-weight: 600;
}
.pricingPage-section section#pricing-cards {
    padding-top: 0 !important;
}
.first.btn {
    background-color: #1fa2ff;
    color: #fff;
    margin-right: 20px;
    font-weight: bold;
    font-size: 18px;
    padding: 12px 32px;
}
@media only screen and  (max-width:768px){
    .pricingPage-cards .row {
        gap: 0 !important;
    }

    .row.box-shadow-employe li {
        font-size: 7px;
    }
    .row.box-shadow-employe div {
        width: 33% !important;
    }

}
