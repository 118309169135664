.b-client{
    margin-top: 60px;text-align: center;
}

.client-data-wrap .clients-no {
    font-size: 5.5rem;
    font-weight: 700;
    font-family: 'Poppins';
    color: #ced4da !important;
    margin: 0 !important;
}
.clients-wrapper-box p {
    display: none;
}
.client-data-wrap .read_more {
    margin-top: 20px;
}
.clients-wrapper-box img {
    width: 73% !important;
    filter: opacity(90%);
    vertical-align: middle;
}

.clients-wrapper-box  {
    padding: 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    line-height: 125px;
    height: 150px;
}

.second-clients-row {
    margin-top: 1.5rem;
}

.clients-wrapper-box  .client-name {
    font-weight: 700;
    color: var(--blue);
}


@media only screen and  (max-width:768px){
    .clients-row {
        margin-top: 0rem !important;
    }
    .second-clients-row {
         margin-top:  0rem !important;
    }
}

@media only screen and (max-width:992px) and (min-width:320px){
    section#fourth .row .col-lg-3 ,section#fourth .row .col-lg-2{
        width: 50% !important;
    }

    .clients-row {
        margin-top: 2rem;
    }
    .clients-wrapper-box {
        margin: 10px 0;
    }
    .features-content-wrap {
        gap: 2rem;
    }
}